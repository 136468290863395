<!--
 * @Description: 地图坐标选取 确定回调传递拾取的坐标，取消回调传递null
 * @Author: 琢磨先生
 * @Date: 2022-05-11 22:12:30
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-12 17:29:43
-->

<template>
  <el-drawer
    title="位置选择"
    v-model="drawer"
    direction="rtl"
    size="80%"
    :before-close="close"
    :destroy-on-close="true"
  >
    <div class="map-body">
      <div id="map" style="height: 100%"></div>
      <div id="search">
        <el-input
          id="keyword"
          v-model="q"
          placeholder="请输入关键字"
          size="large"
          prefix-icon="Search"
        />
      </div>
    </div>

    <template #footer>
      <div style="flex: auto">
        <span style="margin-right: 20px"
          >当前拾取的坐标：{{
            current != null ? current.lng + "," + current.lat : ""
          }}</span
        >
        <el-button type="primary" @click="confirmClick">确定</el-button>
        <el-button @click="cancelClick">取消</el-button>
      </div>
    </template>
  </el-drawer>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  data() {
    return {
      drawer: false,
      q: "",
      current: null,
    };
  },
  setup() {
    return {
      map: null,
      AMap: null,
    };
  },
  /**
   * area:城市区域
   */
  props: ["area", "lng", "lat", "change"],
  watch: {
    area: {
      handler(item) {
        if (item) {
          this.drawer = true;
          this.q = "";
          this.createMap();
        }
      },
    },
  },
  created() {
    this.settings = this.$store.getters.getSettings;
    window._AMapSecurityConfig = {
      securityJsCode: this.settings.amapSecretKey,
    };
  },
  mounted() {
    this.createMap();
  },

  methods: {
    /**
     * 创建地图
     */
    createMap() {
      AMapLoader.load({
        key: this.settings.amapWebAppKey,
        version: "2.0",
        plugins: [
          "AMap.AutoComplete",
          "AMap.PlaceSearch",
          "AMap.Marker",
          "PlaceSearchRender",
        ],
        AMapUI: {
          version: "1.1",
          plugins: [],
        },
        Loca: {
          version: "2.0",
        },
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.map = new AMap.Map("map", {
            zoom: 16,
            center:
              this.area.lng != null && this.area.lat != null
                ? [this.area.lng, this.area.lat]
                : null,
            viewMode: "3D",
            resizeEnable: true,
          });
          //输入提示
          var autoOptions = {
            city: this.area.adcode,
            input: "keyword",
            citylimit: true,
          };
          var auto = new AMap.AutoComplete(autoOptions);
          auto.on("select", (e) => {
            this.createMarker(e.poi.location);
          });
          //点击地图获取当前位置坐标
          this.map.on("click", (e) => {
            this.createMarker(e.lnglat);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /**
     * 确定回调
     */
    confirmClick() {
      if (!this.current) {
        this.$message({
          type: "warning",
          message: "请拾取坐标位置",
        });
        return;
      }
      if (typeof this.change == "function") {
        this.change({
          lng: this.current.lng,
          lat: this.current.lat,
        });
      }
      this.drawer = false;
    },
    /**
     * 取消，放弃选取
     * 回调传递null
     */
    cancelClick() {
      this.drawer = false;
      if (typeof this.change == "function") {
        this.change(null);
      }
    },

    /**
     * 创建显示标记
     */
    createMarker(position) {
      console.log(position);
      this.map.clearMap();
      var marker = new this.AMap.Marker({
        position: position,
        icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        offset: new this.AMap.Pixel(-13, -30),
      });
      this.map.add(marker);
      this.map.setFitView(marker);
      this.map.setZoom(20);
      this.current = {
        lng: position.lng,
        lat: position.lat,
      };
    },
    /**
     * drawer关闭事件
     */
    close() {},
  },
};
</script>

<style>
.map-body {
  height: 100%;
  position: relative;
}
#search {
  position: absolute;
  top: 10px;
  z-index: 2;
  width: 500px;
  left: 50%;
  margin-left: -250px;
}
.amap-sug-result {
  z-index: 9999 !important;
}
.amap-sug-result .auto-item {
  padding: 10px;
}
</style>